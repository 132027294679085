@import "./animations.css";

:root {
  --title-font: "Halfomania-Regular";
  --light-gray-color: #b6b6b6;
  --cyan-color: #1b8f98;
  --light-green-color: #3ad246;
  --pink-color: palevioletred;
  --soft-black-color: #0e1111;
  --sea-green-color: #2e8b57;
}

/* Spinning logo */

.spinning-logo-row {
  justify-content: center;
  align-items: center;
}

.mandala-logo {
  pointer-events: none;
  z-index: 2;
}

.logoHB {
  position: absolute;
  z-index: 3;
}

/* --- LOADING --- */

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* --- INTRO PAGE --- */

.intro-page {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-row-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--title-font);
}

.title-text-intro {
  padding: 0.5rem 2rem;
  border-radius: 10px;
  color: var(--light-gray-color);
  font-size: 3rem;
  text-decoration: none;
  cursor: pointer;
  transition: filter 0.3s ease 0.3s;
  text-shadow: 2px 2px 2px darkcyan;
}

.title-text-intro:hover {
  color: var(--cyan-color);
  color: #bb9847;
}
.title-text-intro:active {
  visibility: hidden;
}

.logos-intro {
  max-width: 15rem;
  max-height: 15rem;
}

/* --- Home Page --- */

.home-page {
  width: 100%;
  height: fit-content;
}

.home-image-div {
  width: 100%;
  height: 90vh;
  background-image: url("/public/assets/home.jpg");
  background-size: cover;
  background-position: center;
}

.scroll-top {
  margin-bottom: 7%;
  background-color: var(--soft-black-color) !important;
  width: 5rem;
}

.logos-home {
  max-width: 18rem;
  position: absolute;
  top: 7rem;
  margin: auto;
  z-index: 4;
}

.link-text-home {
  font-size: 2rem;
}

.link-text-home:hover {
  font-size: 2rem;
  color: var(--sea-green-color);
}

/* --- HEADER --- */

.header {
  width: 100vw;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  right: 0;
  background-color: var(--soft-black-color);
  z-index: 5;
}

.title-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-items-col {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* &.nav-items-col :nth-child(9) {
    color: var(--cyan-color);
  } */
}

.nav-item {
  padding: 0rem 1rem;
  font-size: 1.8em;
  color: var(--light-gray-color);
}

.title-col-compact {
  justify-content: flex-start;
  padding-left: 1rem;
}

.menu-col {
  display: flex;
  justify-content: flex-end;
  padding-right: 2%;
}

.nav-menu-icon {
  font-size: 4rem;
  color: var(--cyan-color);
}

/* dot in NavMenu */
.menu-dropdown :nth-child(3):not(.nav-item-menu) {
  background-color: transparent !important;
}

.menu-dropdown :nth-child(1) {
  display: flex;
  flex-direction: column;
  /* & :nth-child(9) {
    color: var(--cyan-color);
  } */
}
.nav-item-container {
  position: fixed;
  top: 6rem;
  left: 25%;
  bottom: 20%;
  height: fit-content;
  overflow-y: auto;
}

.nav-item-menu {
  font-size: 2.5rem;
}

/* --- FOOTER --- */

#Footer {
  iframe {
    padding: 0.5rem 4rem;
  }
}

.footer {
  width: 100vw;
  height: 3.3rem;
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
  font-size: 1.2rem;
  background-color: var(--soft-black-color);
}

.copyright-text {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  color: var(--light-gray-color);
  font-weight: 1rem;
  font-family: "Nanum Pen Script", cursive;
}

.sc-col {
  max-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(80%);
}

.footer-text {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3rem;
  color: var(--light-gray-color);
  font-family: "Nanum Pen Script", cursive;
}

/* --- INFO-BOTTOM --- */

.info-bottom {
  width: 100vw;
  height: fit-content;
  background-color: var(--soft-black-color);
  opacity: 0.68;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.logos-info-bottom {
  max-width: 8rem;
}

.subscribe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: rgb(46, 3, 46);
  opacity: 0.5;
}

.subscribe-form {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
}

.subscribe-btn {
  width: 10rem;
  height: 3rem;
  margin-top: 2%;
  padding: 1.5%;
  background-color: var(--soft-black-color);
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe-btn-text {
  color: palevioletred;
}

.subscribe-btn-text:hover {
  color: #2e8b57;
}

.credits-div {
  padding: 3%;
}

.icon-bar {
  padding-bottom: 2%;
  padding-top: 5%;
}

.icon-bar-row {
  justify-content: center;
  padding-top: 4%;
}

.icon {
  color: var(--cyan-color);
}

/* --- MUSIC PAGE & MIX PAGE --- */

.music-page {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.music-container {
  width: 100%;
  padding-bottom: 3%;
}

.inner-container {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.release-card {
  width: 22rem;
  height: 23rem;
  margin: 0;
  box-shadow: none;
}

.frame-box {
  width: 22rem;
  height: 19rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-footer {
  padding-top: 2%;
  padding-left: 9%;
  font-size: 1rem;
  font-family: sans-serif;

  filter: brightness(150%);
}

.card-footer :nth-child(2),
.card-footer :nth-child(3) {
  color: var(--cyan-color);
}

/* --- DETAILS PAGE --- */

.details-page {
  width: 100vw;
  height: fit-content;
}

.details-frame-col iframe {
  width: 100%;
  height: 80vh;
  opacity: 0.8;
}

/* --- VIDEO-PAGE --- */

#VideoPage {
  iframe {
    width: 20rem;
    height: 11.25rem;
  }
}

.video-page {
  width: 100vw;
  height: fit-content;
  justify-content: center;
}

.video-container {
  width: 100%;
  justify-content: center;
}

.frame-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* --- GIGS-PAGE --- */

#GigsPage {
  .page-title {
    padding: 1rem;
  }
}

.gigs-page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gigs-box {
  width: 99%;
  font-size: 0.9rem;
  font-family: sans-serif;
}

.gig-row > *:nth-child(n) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-gray-color);
}

.gig-link:hover {
  color: var(--cyan-color);
}

/* --- MERCH PAGE --- */

#MerchPage {
  p {
    font-size: 0.9rem;
    text-align: justify;
  }

  img {
    width: 20rem;
  }
}

.merch-page {
  width: 100vw;
  height: fit-content;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.merch-box {
  width: 98%;
  padding-top: 2%;
}

.merch-frame {
  width: 22rem;
  height: 22rem;
}

.merch-btn {
  position: absolute;
  top: 65%;
}

/* --- ABOUT PAGE--- */

#AboutPage {
  .page-title {
    padding-top: 2rem;
  }
}

.about-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/public/assets/image04.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.about-main-img {
  width: 100vw;
  height: 100vh;
  background-image: url("/public/assets/image03.jpeg");
  background-size: cover;
  background-position: center;
}

.about-main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-2 {
  width: 100%;
}

.image-2 img {
  width: 100%;
}

.about-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-box p {
  text-align: justify;
}

.about-text {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  border-radius: 5px;
  background-color: var(--soft-black-color);
  filter: opacity(70%);
}

.all-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-box {
  padding: 1% 3%;
}

.image3 {
  width: 100%;
}

/* --- CONTACT-PAGE --- */

.contact-page {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
}

.contact-form {
  width: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1% 5% 2% 5%;
  border: 1px solid black;
  background-color: var(--soft-black-color);
  opacity: 0.6;
}

/* --- PAGE NOT FOUND --- */

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  width: 90%;
  height: 2%;
  padding: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  border-radius: 5px;
  border: 3px solid palevioletred;
}

input:focus {
  outline: none;
  border: 3px solid var(--sea-green-color);
}

textarea {
  width: 90%;
  height: 8rem;
  padding: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  font-family: "Helvetica";
  border-radius: 5px;
  border: 3px solid palevioletred;
}

textarea:focus {
  outline: none;
  border: 3px solid var(--sea-green-color);
}

ion-progress-bar {
  --background: black;
  --progress-background: var(--cyan-color);
}

.transparent {
  background: none;
}

.transparent-frame {
  border: 3px solid #000;
  box-shadow: 1px 1px 10px 2px;
  color: #1abec9;
  background-color: var(--soft-black-color);
  opacity: 0.8;
}

.no-padding {
  padding: 0;
}

.hide {
  display: none;
}

.page-title {
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  font-family: var(--title-font);
  font-size: 1.8rem;
  color: var(--pink-color);
  filter: brightness(150%);
}

.page-title-clickable:hover {
  cursor: pointer;
  color: var(--sea-green-color);
}

.test {
  background-color: red;
}

.shadow-img {
  border: 3px solid #000;
  box-shadow: 1px 1px 20px 1px;
  color: #1abec9;
  color: var(--cyan-color);
}

.share-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3%;
}

.share-component p {
  font-size: 1rem;
}

.shadow-no-border {
  box-shadow: 1px 1px 20px 1px;
  color: var(--cyan-color);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* --- PHONE-LANDSCAPE --- */
@media (min-width: 600px) and (orientation: landscape) {
  .logos-home {
    max-width: 13rem;
    top: 2rem;
    z-index: 6;
  }

  .nav-item-container {
    left: 65%;
    top: 0;
  }
  .nav-item-menu {
    font-size: 2rem;
  }

  .video-container {
    width: 90%;
  }
}

/* --- TABLET-PORTRAIT --- */
@media (min-width: 712px) and (orientation: portrait) {
  .logos-home {
    max-width: 28rem;
    top: 8rem;
  }

  .image-2 {
    width: 70%;
  }

  .nav-item-container {
    left: 50%;
  }

  .gigs-box {
    font-size: 1.2rem;
  }

  #VideoPage {
    iframe {
      width: 40rem;
      height: 22.5rem;
    }
  }

  .contact-form {
    width: 60%;
  }

  .subscribe-form {
    width: 40vh;
  }

  #MerchPage {
    img {
      width: 30rem;
    }
  }
}

/* --- TABLET-LANDSCAPE --- */
@media (min-width: 1024px) and (orientation: landscape) {
  .logos-home {
    max-width: 20rem;
    top: 13%;
  }

  .image-2 {
    width: 70%;
  }

  .nav-item-container {
    left: 65%;
  }

  .gigs-box {
    width: 60%;
    font-size: 1.2rem;
  }

  #VideoPage {
    iframe {
      width: 40rem;
      height: 22.5rem;
    }
  }
  .contact-form {
    width: 50%;
  }
}

/* --- LARGE DESKTOP --- */
@media (min-width: 1030px) {
  .title-text {
    font-size: 4.5rem;
  }

  .logos-home {
    width: 30rem;
    height: auto;
    top: 7rem;
  }

  .image-2 {
    padding: 3%;
  }

  .footer {
    height: 3%;
  }

  .info-bottom {
    height: fit-content;
    padding-top: 0;
    padding-bottom: 0;
  }

  .info-bottom-box {
    width: 80vw;
    height: auto;
    padding: 3%;
  }

  .logos-info-bottom {
    max-width: 10rem;
  }

  .icon-bar {
    padding-bottom: 0;
    padding-top: 2%;
  }

  .scroll-top {
    margin-bottom: 3%;
  }

  .music-container {
    width: 80%;
  }

  .details-frame-col iframe {
    margin: 1% 25%;
    height: 65vh;
  }

  #VideoPage {
    iframe {
      width: 31rem;
      height: 18rem;
    }
  }

  .video-container {
    width: 80%;
    justify-content: center;
  }

  .frame-col {
    padding: 2%;
  }

  .gigs-box {
    width: 50%;
    font-size: 1rem;
  }

  .about-container {
    flex-direction: row-reverse;
    padding-top: 15%;
  }

  .image-2 {
    width: 45%;
    height: auto;
    padding-right: 10%;
  }

  .about-box {
    padding-left: 5%;

    p {
      font-size: 1em;
    }
  }

  .all-img-container {
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #AllImageContainer {
    img {
      width: 30rem;
    }
  }

  .img-box {
    padding: 1rem;
  }

  .subscribe {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .subscribe-form {
    width: 50vh;
  }

  #MerchPage {
    img {
      width: 30rem;
    }
  }

  .merch-page {
    height: 70vh;
  }

  .merch-box {
    width: 80%;
    padding: 0;
  }

  .contact-form {
    width: 40%;
  }

  .flashing-btn {
    padding: 1rem 2rem;
  }

  .share-component {
    padding: 1%;
  }
}
