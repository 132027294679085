/* --- Title animation --- */

.title-text {
  letter-spacing: 4px;
  background-image: url("/public/assets/giphy.webp");
  background-size: "cover";
  background-attachment: fixed;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 3rem;
  margin: 0px 0px;
}

/* --- Mandala animation --- */

@keyframes mandala-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .mandala-logo {
    animation: mandala-logo-spin infinite 35s linear;
  }
}

/* --- Frame animation --- */

iframe:not(.merch-frame):not(.sc-frame),
.frame-box img {
  --g: 4px; /* the gap */
  --b: 2px; /* border thickness*/
  --c: gray; /* the color */

  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
  background: var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%),
    var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b), var(--b) 200%;
  cursor: pointer;
  filter: grayscale(20%);
  transition: 0.3s;
}

iframe:hover,
.frame-box img:hover {
  --_p: 75%;
  filter: grayscale(0%);
}

/* --- Navigation animation --- */

a {
  font-family: "Nanum Pen Script";
  color: var(--light-gray-color);
  cursor: pointer;
  text-decoration: none;
}

a span {
  transition: 0.3s linear;
}

a:hover span:nth-child(1) {
  margin-right: 5px;
}

a:hover span:nth-child(2) {
  margin-left: 30px;
}

a:hover span {
  color: var(--light-green-color);
  font-size: 110%;
  text-shadow: 0 0 2px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

/* .btn-shine {
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 35px;
  text-decoration: none;
  white-space: nowrap;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
} */

/* --- FLASHING BUTTON --- */

.flashing-btn {
  width: fit-content;
  padding: 1rem 2rem;
  margin: 1rem 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  display: inline-block;
  font-family: var(--title-font);
  filter: brightness(130%);
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 2100ms infinite;
  -moz-animation: glowing 2100ms infinite;
  -o-animation: glowing 2100ms infinite;
  animation: glowing 2100ms infinite;
  color: var(--cyan-color);
  color: white;
  background-color: var(--soft-black-color);
  opacity: 0.8;
  cursor: pointer;
}

@-webkit-keyframes glowing {
  0% {
    /* background-color: #00a3b2; */
    -webkit-box-shadow: 0 0 3px var(--cyan-color);
  }
  50% {
    /* background-color: #197060; */
    -webkit-box-shadow: 0 0 40px var(--cyan-color);
  }
  100% {
    /* background-color: #1e8b62; */
    -webkit-box-shadow: 0 0 3px var(--cyan-color);
  }
}

@-moz-keyframes glowing {
  0% {
    /* background-color: #00b2af; */
    -moz-box-shadow: 0 0 3px var(--cyan-color);
  }
  50% {
    /* background-color: #00ffb3; */
    -moz-box-shadow: 0 0 40px var(--cyan-color);
  }
  100% {
    /* background-color: #00ae8e; */
    -moz-box-shadow: 0 0 3px var(--cyan-color);
  }
}

@-o-keyframes glowing {
  0% {
    /* background-color: #00b27d; */
    box-shadow: 0 0 3px var(--cyan-color);
  }
  50% {
    /* background-color: #00ffc8; */
    box-shadow: 0 0 40px var(--cyan-color);
  }
  100% {
    /* background-color: #00b262; */
    box-shadow: 0 0 3px var(--cyan-color);
  }
}

@keyframes glowing {
  0% {
  }
  50% {
    box-shadow: 0 0 60px var(--cyan-color);
    color: palevioletred;
  }
  100% {
    box-shadow: 0 0 3px var(--cyan-color);
  }
}
