@font-face {
  font-family: "Halfomania-Regular";
  src: url("/public/fonts/halfomania-regular-webfont.woff") format("truetype"),
    url("/public/fonts/halfomania-regular-webfont.woff2") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/Helvetica.woff") format("truetype"),
    url("/public/fonts/Helvetica-Bold.woff") format("opentype");
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Halfomania-Regular";
  background-image: url("/public/assets/abstract_bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}

p {
  margin: 0;
  padding: 0;
  font-family: "Helvetica", sans-serif;
  font-size: 1.2rem;
  color: var(--light-gray-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
